html {
    min-height: 30rem;
    height: 100%;
    min-width: 400px;
    border: var(--chakra-colors-lightgray-300);
    border-radius: var(--chakra-borderRadius-sm);
    box-shadow: 0px 4px 4px 2px #00000026;
}

body {
    background: var(--chakra-colors-lightgray-300);
}

a {
    color: var(--chakra-colors-gray-600);

    &:hover {
        text-decoration: underline;
    }

    &.disabled {
        pointer-events: none;
        text-decoration: none;
        color: var(--chakra-colors-lightgray-600) !important;
    }
}

:focus:not(:focus-visible):not([role="dialog"]):not([role="menu"]) {
	box-shadow: none !important;
}

.bg-pattern {
    background: #FFF url('./images/bg-pattern.svg') repeat;
}

.near-wallet-selector-modal {
  max-width: 200px;
  max-height: 200px;
}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--chakra-colors-lightgray-600) #FFF;
  
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background: var(--chakra-colors-lightgray-300);
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: var(--chakra-colors-lightgray-600);
      border-radius: 6px;
      // border: 3px solid #FFF;
    }
  }